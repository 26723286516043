import { getAccessToken } from '../context';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

const cache = {};
const promises = {};

export async function fetchWithPromise(key, path, params) {
  // Return existing promise if available
  if (promises[key]) {
    return promises[key];
  }

  // Else, fetch the data
  promises[key] = fetchFromServer(path, params);

  const result = await promises[key];
  delete promises[key];

  return result;
}

export async function fetchWithCache(key, path) {
  // Return from cache if available
  if (cache[key] && cache[key].types) {
    return cache[key];
  }

  // Return existing promise if available
  if (promises[key]) {
    return promises[key];
  }

  // Else, fetch the data
  promises[key] = fetchFromServer(path);

  const result = await promises[key];
  cache[key] = result;
  delete promises[key];

  return result;
}

async function fetchFromServer(path, params) {
  try {
    const url = `${process.env.REACT_APP_HEAVYSET_API}/${path}`;
    const response = await axios.get(url, {
      params: params || {},
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    });
    const data = response.data;
    return { data };
  } catch (ex) {
    if (ex.response?.data?.statusCode === StatusCodes.UNAUTHORIZED) {
      console.log('TODO, logout, login or forward to contact page');
    }
    return { errorMessage: ex.response?.data?.message || ex.message };
  }
}
