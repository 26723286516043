/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { DataContext } from './data-context';
import PhoneValidator from '../services/phone-validator';
import PropTypes from 'prop-types';
import { leadStatus } from '../constants/lead-status.enum';

export const OptInCheck = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.optInCheck || false;
};

export const Title1 = () => {
  const { data } = useContext(DataContext);
  return <>{data?.styleData?.text?.title1 || ''}</>;
};

export const Subtitle1 = () => {
  const { data } = useContext(DataContext);
  return <>{data?.styleData?.text?.subtitle1 || ''}</>;
};

export const googlePlaceId = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.text?.googlePlaceId;
};

export const confirmNeeded = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.confirmNeeded;
};

export const smsConfirm = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.smsConfirm;
};

export const showCopyright = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.showCopyright;
};

export const showDetails = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.showDetails;
};

export const showHomeYear = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.showHomeYear;
};

export const ShowB2b = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.showB2b;
};

export const ShowStaff = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.showStaff;
};

export const Button1Text = () => {
  const { data } = useContext(DataContext);
  return <>{data?.styleData?.text?.button1 || 'Continue'}</>;
};

// eslint-disable-next-line react/prop-types
export const Button2Text = ({ status }) => {
  const { data } = useContext(DataContext);
  const button2 = data?.styleData?.text?.button2 || '';
  if (button2) {
    return <>{button2}</>;
  }
  if (status === leadStatus.pendingNoApt) {
    return <>Submit</>;
  } else {
    return <>Select Day & Time</>;
  }
};

export const ExistingLeadGreeting1 = ({ firstName }) => {
  const { data } = useContext(DataContext);
  let greeting = data?.styleData?.text?.existingLeadGreeting1 || '';
  if (greeting) {
    greeting = (greeting || '').replace(/\[\[first_name\]\]/g, firstName);
    return <>{greeting}</>;
  }
  return '';
};

export const ExistingLeadGreeting2 = ({ firstName }) => {
  const { data } = useContext(DataContext);
  let greeting = data?.styleData?.text?.existingLeadGreeting2 || '';
  if (greeting) {
    greeting = (greeting || '').replace(/\[\[first_name\]\]/g, firstName);
    return <>{greeting}</>;
  }
  return '';
};

// URL syntax in disclaimer text supported [[url,Terms of Service,https://shugarmansbath.com/terms-of-service/]]
// Function to process the disclaimer text and return an array of strings and JSX elements
const processDisclaimer = (disclaimerText) => {
  // Define the regex pattern to find url, phone, and bold patterns
  const pattern = /\[\[(url|phone|b),(.*?)(?:,(.*?))?\]\]/g;

  let parts = [];
  let lastIndex = 0;

  disclaimerText.replace(pattern, (match, type, textOrNumber, url, index) => {
    // Push the text before the match
    parts.push(disclaimerText.slice(lastIndex, index));

    if (type === 'url') {
      // Create a React element for the URL link
      parts.push(
        <a href={url} key={url} target="_blank" rel="noreferrer">
          {textOrNumber}
        </a>
      );
    } else if (type === 'phone') {
      // Create a React element for the phone link
      const telFormat = PhoneValidator.toTelFormat(textOrNumber);
      const regFormat = PhoneValidator.toPlain(textOrNumber);
      parts.push(
        <a href={`tel:${telFormat}`} key={textOrNumber}>
          {regFormat}
        </a>
      );
    } else if (type === 'b') {
      // Create a React element for bold text
      parts.push(<b key={`b-${index}`}>{textOrNumber}</b>);
    }

    // Update the lastIndex to the end of the current match
    lastIndex = index + match.length;
  });

  // Push the remaining text after the last match
  parts.push(disclaimerText.slice(lastIndex));

  // Additional processing to handle \\n
  parts = parts.flatMap((part) => {
    if (typeof part === 'string') {
      return part.split('\\n').reduce((acc, segment, index, array) => {
        acc.push(segment);
        if (index < array.length - 1) acc.push(<div key={`br-${index}`} style={{ height: '4px' }} />);
        return acc;
      }, []);
    } else {
      return part;
    }
  });

  return parts;
};

export const DisclaimerText = () => {
  const { data } = useContext(DataContext);
  const disclaimer = data?.styleData?.text?.disclaimer;
  if (disclaimer) {
    return <>{processDisclaimer(disclaimer)}</>;
  }
  return (
    <>
      You agree you would like to learn about our promotions &amp; you understand we use an automatic phone system and
      text messages to reach you. All calls and in-person meetings will be recorded for quality and training purposes.
      Your Information will only be used by{' '}
      <strong>
        <CompanyName />
      </strong>
    </>
  );
};

export const ConfirmNote = ({ defaultNote }) => {
  const { data } = useContext(DataContext);
  const confirmNote = data?.styleData?.text?.confirmNote;
  if (confirmNote) {
    return <>{processDisclaimer(confirmNote)}</>;
  }
  return defaultNote;
};

export const DisclaimerTextShort = () => {
  const { data } = useContext(DataContext);
  const disclaimer = data?.styleData?.text?.disclaimer;
  if (disclaimer) {
    return <>{processDisclaimer(disclaimer)}</>;
  }
  return (
    <>
      You consent to receive promotions via automated calls and texts from{' '}
      <b>
        <CompanyName />
      </b>{' '}
      which records interactions for quality. Your info is used solely by us.
    </>
  );
};

export const BulletPoints1 = () => {
  const { data } = useContext(DataContext);
  let bulletPoints1 = data?.styleData?.text?.bulletPoints1;
  bulletPoints1 = bulletPoints1 && bulletPoints1.length ? bulletPoints1 : [''];
  bulletPoints1 = bulletPoints1.map((b) => (b || '').trim()).filter((b) => !!b);
  const bullets = bulletPoints1.map((b, i) => {
    return <li key={`b1-${i}`}>{b}</li>;
  });
  return <>{bullets}</>;
};

export const CompanyName = () => {
  const { data } = useContext(DataContext);
  return <>{data?.styleData?.contact?.companyName || ''}</>;
};

export const Logo1 = () => {
  const { data } = useContext(DataContext);
  const logo1 = data?.styleData?.images?.logo1 || '';
  if (!logo1) {
    return <></>;
  }
  return <img src={logo1} alt="" className="logo"></img>;
};

export const ContactPhone = ({ plain }) => {
  const { data } = useContext(DataContext);
  const phone = data?.styleData?.contact?.phone || '';
  const telFormat = PhoneValidator.toTelFormat(phone);
  const regFormat = PhoneValidator.toPlain(phone);
  const className = plain ? 'phone_plain' : 'phone_cal mt-3';
  return (
    <a className={className} href={`tel:${telFormat}`}>
      {regFormat}
    </a>
  );
};

export const ConfirmBoxText = () => {
  const { data } = useContext(DataContext);
  let confirmBoxText = (data?.styleData?.text?.confirmBoxText || '').trim();
  if (!confirmBoxText) {
    return '';
  }
  const phone = data?.styleData?.contact?.phone || '';
  confirmBoxText = (confirmBoxText || '').replace(/\[\[phone\]\]/g, `[[phone,${phone}]]`);
  return processDisclaimer(confirmBoxText);
};

export const CountryAlpha3 = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.contact?.countryAlpha3;
};

export const CountryAlpha2 = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.contact?.countryAlpha2;
};

export const ProductList = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.text?.products || [];
};

export const ProductsTitle = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.text?.productsTitle || '';
};

export const HearAboutList = () => {
  const { data } = useContext(DataContext);
  return (data?.styleData?.text?.hearAbout || []).filter((h) => !!(h || '').trim());
};

export const HearAboutTitle = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.text?.hearAboutTitle || '';
};

export const MultiSelect = () => {
  const { data } = useContext(DataContext);
  return data?.styleData?.text?.multiSelect || false;
};

export const ExistingSelect = () => {
  const { data } = useContext(DataContext);
  const products = ProductList();
  if (products?.length > 0) {
    return data?.styleData?.text?.existingSelect || false;
  }
  return false;
};

ContactPhone.propTypes = {
  plain: PropTypes.bool
};
