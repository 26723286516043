export const colorsDefault = `
/* example file on how to change colors */
:root {
  --body-background: #fef0eb;
  --primary-background: #f05523;
  --primary-text: #ffffff;
  --secondary-background: #ffffff;
  --secondary-text: #000000;
  --secondary-outline: #737072;
  --disclaimer-text: #999999;
  --disabled-color: #c0c0c0;
  --shadow-color: rgba(134, 133, 133, 0.35);
  --calendar-box-background: #faebd7;
  --box-hover-background: #e5e4dd;
}

body {
  background-color: var(--body-background) !important;
}

/* --body-background */
html {
  background: var(--body-background) !important;
}
body #root .main_bg,
body #root .calender_area .react_calendar,
body #root .contact_main {
  background-color: var(--body-background) !important;
}

/* --primary-background */
body #root .form-check-input[type="radio"]:active,
body #root .form-check-input[type="radio"]:focus-within,
body #root .form-check-input:checked,
body #root .calendar_color_area {
  background: var(--primary-background);
}
body #root .app_circle:hover .app_btn,
body #root .selected_app_btn,
body #root .reg_btn,
body #root .email_btn,
body #root .dot {
  background-color: var(--primary-background);
}
body #root .field_area {
  border: 1px solid var(--primary-background);
}
body #root .email_list li:before,
body #root span,
body #root .col-center,
body #root .confirm_p,
body #root .phone_cal,
body #root .phone_plain,
body #root .email-h1 div {
  color: var(--primary-background);
}
body #root .calendar .body .selected {
  border: 3px solid var(--primary-background);
}
body #root .circle-check-primary-svg,
body #root .primary-svg {
  fill: var(--primary-background);
}

/* --secondary-text */
body #root .app_circle:hover .app_time,
body #root .reg_btn,
body #root .reg_input,
body #root .email_input,
body #root .calendar .body .cell .number,
body #root .reg_p {
  color: var(--secondary-text);
}
body #root .react-calendar__tile--active,
body #root .react-calendar button:enabled:hover {
  color: var(--secondary-text) !important;
}

/* --disclaimer-text */
body #root .email_box .email_p.disclaimer-text,
body #root .email_box .email_p_short.disclaimer-text {
  color: var(--disclaimer-text);
  a {
    color: var(--disclaimer-text);
  }
}

/* --secondary-outline */
body #root .note_area p,
body #root strong,
body #root p {
  color: var(--secondary-outline);
}
body #root .app_time,
body #root .calendar .body .disabled,
body #root .calendar .days {
  color: var(--secondary-outline);
}
body #root .app_circle {
  border: 1px solid var(--secondary-outline);
}

/* --disabled-color */
body #root .circle-check-disabled-svg {
  fill: var(--disabled-color);
}

/* --shadow-color */
body #root .contact_box,
body #root .reg_box,
body #root .schedule_box,
body #root .email_box {
  box-shadow: var(--shadow-color) 0px 5px 10px;
}

/* --secondary-background */
body #root .react-calendar__tile--active,
body #root .react-calendar button:enabled:hover {
  background: var(--secondary-background) !important;
}
body #root .app_btn,
body #root .schedule_box,
body #root .email_box,
body #root .contact_box,
body #root .calendar .body .selected,
body #root .reg_box {
  background-color: var(--secondary-background);
}
body #root .calendar_color_area {
  border: 6px double var(--secondary-background);
}
body #root .form-check-input[type="radio"]:active,
body #root .form-check-input[type="radio"]:focus-within,
body #root .form-check-input:checked {
  border: 3px solid var(--secondary-background);
}

/* --primary-text */
body #root .calendar_date_time p,
body #root .calendar_date_time h2 span,
body #root .email_btn:hover,
body #root .email_btn {
  color: var(--primary-text);
}

/* --calendar-box-background */
body #root .calendar {
  background: var(--calendar-box-background);
}

/* --box-hover-background */
body #root .app_circle:hover,
body #root .calendar .body .cell:hover {
  background: var(--box-hover-background);
}
body #root .react-calendar {
  background: var(--box-hover-background) !important;
}

`;
