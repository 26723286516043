import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataContext } from './data-context';

export const DataProvider = ({ children, tokenData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (tokenData) {
      setData(tokenData);
    }
  }, [tokenData]);

  return (
    <>
      <DataContext.Provider value={{ data, setData }}>{children}</DataContext.Provider>
    </>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node,
  tokenData: PropTypes.any
};
