import { parsePhoneNumber } from 'awesome-phonenumber';

export class PhoneValidator {
  static for(value) {
    const phone = this.isValid(value);
    return !!phone;
  }

  static normalize(value) {
    try {
      const phone = this.toPhone(value);
      if (phone && phone.valid) {
        return phone.number.e164;
      }
      return value;
    } catch (ex) {
      return value;
    }
  }

  static normalizeSignificant(value) {
    try {
      const phone = this.toPhone(value);
      if (phone && phone.valid) {
        const significant = phone.number.significant;
        if (significant) {
          return `(${significant.slice(0, 3)}) ${significant.slice(3, 6)}-${significant.slice(6)}`;
        }
      }
      return undefined;
    } catch (ex) {
      this.logger.warn(ex);
      return undefined;
    }
  }

  static toPhone(value) {
    value = (value || '').replace(/=/g, '').trim();
    const phone = parsePhoneNumber(value);
    if (phone.regionCode) {
      return phone;
    }
    return parsePhoneNumber(value, { regionCode: 'US' });
  }

  static isValid(value) {
    return !!this.toPhone(value)?.valid;
  }

  static toPlain(value) {
    const phone = PhoneValidator.normalize(value);
    if (phone) {
      const parsedPhone = parsePhoneNumber(phone);
      if (parsedPhone.valid) {
        return parsedPhone.number.international.replace('+1 ', '');
      }
    }
    return undefined;
  }

  static toExtraPlain(value) {
    const phone = PhoneValidator.normalize(value);
    if (phone) {
      const parsedPhone = parsePhoneNumber(phone);
      if (parsedPhone.valid) {
        return parsedPhone.number.international.replace('+1 ', '').replace(/-/g, '');
      }
    }
    return undefined;
  }

  static toTelFormat(value) {
    const phone = PhoneValidator.toPhone(value);
    if (!phone.valid) {
      return '';
    }
    return phone.number.international.replace(/-/g, '').replace(/ /g, '');
  }

  static dashFormat(value) {
    const phone = PhoneValidator.toPhone(value);
    if (!phone.valid) {
      return '';
    }
    return phone.number.national.replace(/[()]/g, '').replace(/ /g, '-');
  }
}

export default PhoneValidator;
